<template>
  <div>
    <TopMenuSubheadline :title="title"/>
    <div class="app-content-box bg-gray wrapper">
      <div class="container mt-4">
        <div class="mt-4">
          <div class="top-heading-medium text-center">nazwij swój plan czytelniczy</div>
          <div class="row align-items-center">
            <div class="col-12">
              <div class="input-container mb-0">
                <input type="text" class="form-input w-100"
                :class="{'input-validation': isTitleValid}"
                placeholder="Mój plan czytelniczy" v-model="trainingPlanTitle"
                >
              </div>
            </div>
          </div>
          <div class="top-heading-medium text-center mt-4">ile stron chcesz przeczytać podczas jednej sesji?</div>
          <div class="row align-items-center">
            <div class="col-6">
              <div class="input-container mb-0">
                <input type="number" class="form-input w-100"
                :class="{'input-validation': isInputValid}"
                placeholder="Podaj ilość stron" v-model="pagesPerSession"
                >
              </div>
            </div>
            <div class="col-6">
              <div class="input-spinner">
                <div class="input-spinners normal ">
                  <div class="input-spinner-wrapper input-spinner-wrapper-single input-spinner-timespan full">
                    <input type="hidden" name="input-minute">
                    <VerticalSlider :options="pagesTimesArray" @active-index="setPagesTimes" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="section-spacer section-spacer-black">
        <div class="input-spinner mt-4">
          <div class="top-heading-medium text-center">jak długo chcesz czytać z&nbsp;trenerem?</div>
          <div class="input-spinners normal ">
            <div class="input-spinner-wrapper input-spinner-wrapper-single input-spinner-time">
              <input type="hidden" name="input-minute">
                    <VerticalSlider :options="trainingDaysArray" @active-index="setTrainingDays" />
            </div>
            <div class="input-spinner-wrapper input-spinner-wrapper-single input-spinner-timespan">
              <input type="hidden" name="input-minute">
                    <VerticalSlider :options="trainingTimesArray" @active-index="setTrainingTimes" />
            </div>
          </div>
        </div>
        <div>
            <button class="btn-black-full w-100 mt-5 mb-3" @click="validInput">Dalej</button>
      </div>
      </div>
    </div>
  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
import VerticalSlider from '../components/VerticalSlider'
export default {
  name: 'TrainingPlanPages',
  components: {
    TopMenuSubheadline,
    VerticalSlider
  },
  data () {
    return {
      title: 'Edytuj plan stronnicowy',
      planTitle: '',
      isTitleValid: false,
      isInputValid: false,
      url: '',
      pagesTimesArray: ['dziennie', 'tygodniowo'],
      trainingDaysArray: [1, 2, 3, 4, 5, 6],
      trainingTimesArray: ['tygodni', 'miesięcy']
    }
  },
  methods: {
    submit () {
      const pagesTimeUnit = this.pagesTimeType === 'dziennie' ? 'day' : 'week'
      const fullTimeUnit = this.timeWithTrainer === 'tygodni' ? 'week' : 'month'
      const getData = `name=${this.trainingPlanTitle}&type=pages&full_time_unit=${fullTimeUnit}&full_time=${this.daysWithTrainer}&pages_per=${this.pagesPerSession}&pages_per_unit=${pagesTimeUnit}`
      this.$https('/plans', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: getData
      }).then(response => {
        this.$router.push({ name: 'TrainingPlanSelectBooks', params: { plan_id: response.data.response.id } })
      })
        .catch(error => {
          console.log('', error)
        })
    },
    validInput () {
      let checkTitleInput = this.trainingPlanTitle
      checkTitleInput = checkTitleInput.replace(/\s/g, '')
      if (checkTitleInput === '' && this.pagesPerSession === '') {
        this.isTitleValid = true
        this.isInputValid = true
      } else if (checkTitleInput === '') {
        this.isTitleValid = true
        this.isInputValid = false
      } else if (this.pagesPerSession === '') {
        this.isTitleValid = false
        this.isInputValid = true
      } else {
        this.isInputValid = false
        this.isTitleValid = false
        // this.url = '/wybierz-ksiazki'
        this.submit()
      }
    },
    setPagesTimes (index) {
      this.pagesTimeType = this.pagesTimesArray[index]
    },
    setTrainingDays (index) {
      this.daysWithTrainer = this.trainingDaysArray[index]
    },
    setTrainingTimes (index) {
      this.timeWithTrainer = this.trainingTimesArray[index]
    }
  },
  created () {
    this.pagesTimeType = 'dziennie'
    this.daysWithTrainer = 1
    this.timeWithTrainer = 'tygodni'
  },
  computed: {
    trainingPlanTitle: {
      get () {
        return this.$store.state.trainingPlan.trainingPlanTitle
      },
      set (value) {
        this.$store.commit('updateTrainingPlanTitle', value)
      }
    },
    pagesPerSession: {
      get () {
        return this.$store.state.trainingPlan.pagesPerSession
      },
      set (value) {
        this.$store.commit('updatePagesPerSession', value)
      }
    },
    pagesTimeType: {
      get () {
        return this.$store.state.trainingPlan.pagesTimeType
      },
      set (value) {
        this.$store.commit('updatePagesTimeType', value)
      }
    },
    daysWithTrainer: {
      get () {
        return this.$store.state.trainingPlan.daysWithTrainer
      },
      set (value) {
        this.$store.commit('updateDaysWithTrainer', value)
      }
    },
    timeWithTrainer: {
      get () {
        return this.$store.state.trainingPlan.timeWithTrainer
      },
      set (value) {
        this.$store.commit('updateTimeWithTrainer', value)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.app-content-box {
  min-height: 100vh;
}
  .wrapper {
    padding-top: 75px;
    /*padding-bottom: calc(60px + env(safe-area-inset-bottom));*/
    padding-bottom: 80px;
  }
  .input {
    &-validation {
      border: 1px solid red;
    }
  }
</style>
